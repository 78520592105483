<template>
  <button
    class="arrow-btn"
    :class="{
      'curve-left': !arrowLeft,
      'curve-right': !arrowRight,
      'arrow-btn--left': arrowLeft,
      'arrow-btn--right': arrowRight,
      'router-link-exact-active': isActiveExact,
      done: done,
    }"
    @click="goTo"
    :disabled="disabled || !to"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    arrowLeft: {
      type: Boolean,
      default: false,
    },
    arrowRight: {
      type: Boolean,
      default: false,
    },
    done: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
    replace: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasNoPath() {
      return Object.keys(this.to).length === 0
    },

    isActiveExact() {
      return this.to ? this.$route.name === this.to.name : false
    },
  },

  methods: {
    goTo() {
      if (this.isActiveExact || this.hasNoPath) return

      if (this.replace) {
        this.$router.replace(this.to)
      } else {
        this.$router.push(this.to)
      }
    },
  },
}
</script>
