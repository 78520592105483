var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbr-new-excercise"},[_c('main-app-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.$attrs.id)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedExercise.title)+" ")]):_c('span',[_vm._v(" Add New Exercise ")])]},proxy:true},(!_vm.isCompleted)?{key:"actions",fn:function(){return [_c('arrow-button',{attrs:{"done":_vm.stage > 1,"to":{
          name: 'new.exercise.details',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","replace":""}},[_vm._v(" Exercise Details ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 2,"to":{
          name: 'new.exercise.instructions',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-left":"","arrow-right":"","replace":""}},[_vm._v(" Exercise Steps ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 3,"to":{
          name: 'new.exercise.swaps',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-left":""}},[_vm._v(" Swap Exercises ")])]},proxy:true}:{key:"actions",fn:function(){return [_c('arrow-button',{attrs:{"done":_vm.stage > 1,"to":{
          name: 'exercise.details',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","replace":""}},[_vm._v(" Exercise Details ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 2,"to":{
          name: 'exercise.instructions',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-left":"","arrow-right":"","replace":""}},[_vm._v(" Exercise Steps ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 3,"to":{
          name: 'exercise.swaps',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-left":""}},[_vm._v(" Swap Exercises ")])]},proxy:true}],null,true)}),_c('div',{staticClass:"px-5 pt-4 pb-8 mt-5 mb-12 px-lg-12 px-md-6"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }