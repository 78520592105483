<template>
  <div class="bbr-new-excercise">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="$attrs.id">
          {{ selectedExercise.title }}
        </span>
        <span v-else> Add New Exercise </span>
      </template>

      <template v-if="!isCompleted" v-slot:actions>
        <arrow-button
          :done="stage > 1"
          :to="{
            name: 'new.exercise.details',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          replace
        >
          Exercise Details
        </arrow-button>

        <arrow-button
          :done="stage > 2"
          :to="{
            name: 'new.exercise.instructions',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-left
          arrow-right
          replace
        >
          Exercise Steps
        </arrow-button>

        <arrow-button
          :done="stage > 3"
          :to="{
            name: 'new.exercise.swaps',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-left
        >
          Swap Exercises
        </arrow-button>
      </template>

      <template v-else v-slot:actions>
        <arrow-button
          :done="stage > 1"
          :to="{
            name: 'exercise.details',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          replace
        >
          Exercise Details
        </arrow-button>

        <arrow-button
          :done="stage > 2"
          :to="{
            name: 'exercise.instructions',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-left
          arrow-right
          replace
        >
          Exercise Steps
        </arrow-button>

        <arrow-button
          :done="stage > 3"
          :to="{
            name: 'exercise.swaps',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-left
        >
          Swap Exercises
        </arrow-button>
      </template>
    </main-app-bar>

    <div class="px-5 pt-4 pb-8 mt-5 mb-12 px-lg-12 px-md-6">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/elements/ArrowButton'
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Exercise',

  components: {
    MainAppBar,
    ArrowButton,
  },

  computed: {
    ...mapGetters({
      exerciseEquipments: 'exercises/getSelectedExerciseEquipments',
    }),

    ...mapState({
      selectedExercise: (state) => state.exercises.selectedExercise,
    }),

    stage() {
      return this.$route.meta ? this.$route.meta.stage : 1
    },

    next() {
      return this.$attrs.id || false
    },

    isCompleted() {
      return !!this.selectedExercise.completed_at
    },
  },

  async created() {
    let exercise = this.$attrs.id

    if (exercise) {
      await this.fetchExercise(exercise)
    }
  },

  methods: {
    ...mapActions({
      getExercise: 'exercises/getExercise',
    }),

    ...mapMutations({
      clearSelectedExerciseEquipments:
        'exercises/clearSelectedExerciseEquipments',
    }),

    async fetchExercise(exerciseId) {
      await this.getExercise(exerciseId)
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearSelectedExerciseEquipments()

    next()
  },
}
</script>
